import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['localhost', `${environment.apiUrl}`],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.TryCatch(),
    new Sentry.Replay()
  ],
  environment: environment.production ? 'production' : 'development',
  tracesSampleRate: environment.production ? 1.0 : 0.1,
  replaysOnErrorSampleRate: environment.production ? 1.0 : 0.1,
  replaysSessionSampleRate: environment.production ? 1.0 : 0.1
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error: any) => console.error(error));
